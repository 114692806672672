import React from "react"

import { Link } from "gatsby"

const Financialdemo = props => {
  return (
    <>
      <div className="container-fluid discrim-bg-grey ">
        <div className="row bg-color">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-6 col-md-12 justify-content-center d-flex  flex-column schedule-discrim-demo">
                <h5>
                  See How Succeed will <br />
                  work for your organization
                </h5>
                <div className="discrim-demobtn">
                  <Link to="/contact#schedule">
                    <button className="discrim-demo-btn">
                      Schedule a Demo
                    </button>
                  </Link>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 bottom-img">
                <img
                  src="https://backendev.succeedlearn.com/wp-content/uploads/2020/12/cheerful-business-people-job-is-done-group-office-workers-happy-hit-their-own-records-being-successful.png"
                  alt="succeed-works"
                  className="bottom-img-prop"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </>
  )
}

export default Financialdemo
