import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
const Bestprice = props => {
  return (
    <>
      <section className="financialbestprice" id="financialbestprice">
        <div className="container">
          <div className="row">
            <div className="col-lg-6  col-md-12 best-price-human order-2 order-lg-1 order-md-2">
              <Img
                fixed={props.humanimage}
                alt="best-price"
                className="bestpriceimg"
              />
            </div>

            <div className="col-lg-6  col-md-12 text-lg-center order-1 order-lg-2 order-md- best-content-demo">
              <img src={props.demotitleimage} alt="best-price3" />

              <img
                src={props.demosubtitleimage}
                className="incred-price"
                alt="best-price1"
              />

              <div className="price-btn">
                <Link to="/contact#schedule">
                  <button className="btn btn-lg btn-price-btn">
                    Let's discuss!
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Bestprice
