import React, { Component } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Financialdemo from "../components/Financial-bottom-demo"
import Logoimg from "../components/Clients"
import Cathero from "../components/Category-hero"
import Layout from "../components/layout"
import SEO from "../components/Seo"

import Coursedetails from "../components/Courses-details"
import Advantage from "../components/Succeed-advantage"
import Bestprice from "../components/Bestprice-demo"
import Crsehighlight from "../components/Course-highlights"
import Quote from "../components/Testimonials"

class financialcompliance extends Component {
  render() {
    let herodata = this.props.data.wordpressPage
    let title = herodata.acf.title
    let yoast = herodata.yoast_head
    let description = herodata.acf.description
    let subtitle = herodata.acf.subtitle
    let image = herodata.acf.image.localFile.childImageSharp.fluid.originalImg
    let tabimage =
      herodata.acf.tablet_image.localFile.childImageSharp.fluid.originalImg
    let mobimage =
      herodata.acf.mobile_image.localFile.childImageSharp.fluid.originalImg

    //  Course Portofolio

    let crse_title = herodata.acf.course_portfolio_title
    let crsimg1 = herodata.acf.crseImage1.localFile.childImageSharp.fixed
    let crse1_title = herodata.acf.crseTitle
    let crse1_subtitle = herodata.acf.title_subtitle
    let crse1_desc = herodata.acf.wordpress_1st_description

    let crsimg2 = herodata.acf.crseImage2.localFile.childImageSharp.fixed
    let crse2_title = herodata.acf.title2
    let crse2_subtitle = herodata.acf.title_subtitle
    let crse2_desc = herodata.acf.wordpress_2nd_description

    let crsimg3 = herodata.acf.crseImage3.localFile.childImageSharp.fixed
    let crse3_title = herodata.acf.title3
    let crse3_subtitle = herodata.acf.title_subtitle
    let crse3_desc = herodata.acf.wordpress_3rd_description

    let crsimg4 = herodata.acf.crseImage4.localFile.childImageSharp.fixed
    let crse4_title = herodata.acf.title4
    let crse4_subtitle = herodata.acf.title_subtitle
    let crse4_desc = herodata.acf.wordpress_4th_description

    // Demo Images query

    let demo_human_image =
      herodata.acf.human_image.localFile.childImageSharp.fixed

    let demo_title_image =
      herodata.acf.demo_title_image.localFile.childImageSharp.fluid.originalImg

    let demo_subtitle_image =
      herodata.acf.demo_subtitle_image.localFile.childImageSharp.fluid
        .originalImg

    // Demo Bottom Image

    let bottom_demo_img = herodata.acf.bottom.localFile.childImageSharp.fixed
    return (
      <>
        <Layout
          logo={this.props.data.allWordpressAcfSucceedlearnLogo.edges}
          favicon={this.props.data.allWordpressAcfSucceedlearnApple.edges}
        >
          <SEO title={{ yoast }} />
          {/* Hero Section  */}
          <section className="financial-hero" id="financial-hero">
            <Cathero
              data={title}
              img={image}
              tabimage={tabimage}
              mobimage={mobimage}
              desc={description}
              subtitle={subtitle}
            />
          </section>

          {/* Clients Section */}

          <section className="clients" id="clients">
            <Logoimg
              clientsrow={this.props.data.allWordpressAcfClientsLogos}
              title={herodata.acf.clients_title}
            />
            <hr className="client-below" />
          </section>

          {/* Advantage Section */}
          <Advantage
            title={herodata.acf.adv_title}
            img1={
              herodata.acf.imageone.localFile.childImageSharp.fluid.originalImg
            }
            img2={
              herodata.acf.imagesecond.localFile.childImageSharp.fluid
                .originalImg
            }
            img3={
              herodata.acf.imagethree.localFile.childImageSharp.fluid
                .originalImg
            }
            imgdesc1={herodata.acf.image1desc}
            imgdesc2={herodata.acf.image2}
            imgdesc3={herodata.acf.image3}
          />
          <hr className="client-below" />

          {/* Courses Portfolio */}

          <Coursedetails
            title={crse_title}
            img1={crsimg1}
            crs1title={crse1_title}
            crse1subtitle={crse1_subtitle}
            crse1desc={crse1_desc}
            img2={crsimg2}
            crs2title={crse2_title}
            crse2subtitle={crse2_subtitle}
            crse2desc={crse2_desc}
            img3={crsimg3}
            crs3title={crse3_title}
            crse3subtitle={crse3_subtitle}
            crse3desc={crse3_desc}
            img4={crsimg4}
            crs4title={crse4_title}
            crse4subtitle={crse4_subtitle}
            crse4desc={crse4_desc}
          />

          {/* Best Price Demo */}

          <Bestprice
            humanimage={demo_human_image}
            demotitleimage={demo_title_image}
            demosubtitleimage={demo_subtitle_image}
          />

          <section className="harass-highlights" id="harass-highlights">
            <Crsehighlight
              page={"Harassment"}
              page={"Financial"}
              section={"highlights"}
              h3title={"title-highlights"}
              harassh3title={"title-harass-highlights"}
              financialh3title={"title-financial-highlights"}
              harassection={"harass-highlights"}
              financialpage={"Financial"}
              img1={
                herodata.acf.crse_highlights1.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle1={herodata.acf.crse_highlights1_title_and_description}
              img2={
                herodata.acf.crse_highlights2.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle2={herodata.acf.crse_highlights2_title_and_description}
              img3={
                herodata.acf.crse_highlights3.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle3={herodata.acf.crse_highlights3_title_and_description}
              img4={
                herodata.acf.crse_highlights4.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle4={herodata.acf.crse_highlights4_title_and_description}
              img5={
                herodata.acf.crse_highlights5.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle5={herodata.acf.crse_highlights5_title_and_description}
              img6={
                herodata.acf.crse_highlights6.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle6={herodata.acf.crse_highlights6_title_and_description}
              img7={
                herodata.acf.crse_highlights7.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle7={herodata.acf.crse_highlights7_title_and_description}
              img8={
                herodata.acf.crse_highlights8.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle8={herodata.acf.crse_highlights8_title_and_description}
              img9={
                herodata.acf.crse_highlights9.localFile.childImageSharp.fluid
                  .originalImg
              }
              crsetitle9={herodata.acf.crse_highlights9_title_and_description}
            />
          </section>
          <hr style={{ margin: 0, borderTop: "2px solid #a8a8a8" }} />

          {/* Testimonials */}
          <section className="testimonials" id="testimonials">
            <Quote
              testimonialtitle={"What our clients have to say"}
              title={"Roja Puppala "}
              subtitle={"Learning & Development Specialist"}
              description={
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
              }
            />
          </section>

          <section className="financial-btm-demo" id="financial-btm-demo">
            <Financialdemo btmimg={bottom_demo_img} />
          </section>

          <section className="course-domains" id="course-domains">
            <div className="container">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6 title">
                  <h5>Our Other Course Domains</h5>
                </div>
                <div className="col-md-3"></div>
              </div>

              <div className="row py-3">
                <div className="col-lg-4 col-md-12 crs-card">
                  <div className="card" style={{ width: "18rem" }}>
                    <Link to="/Harassment-discrimination-courses">
                      <img
                        className="card-img-top"
                        src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/ufwaunp0cguzwslnslvn.png"
                        alt="infosec"
                        style={{ margin: 0 }}
                      />
                      <div className="card-body card-titledesc1">
                        <p className="card-text">
                          Discrimination & Harassment Prevention
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 crs-card">
                  <Link to="/Infosec-data-protection">
                    <div className="card" style={{ width: "18rem" }}>
                      <img
                        className="card-img-top"
                        src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1608039154/succeedlearn/bttjvhuyluw9jtpaed5k.png"
                        alt="infosec"
                        style={{ margin: 0 }}
                      />
                      <div className="card-body card-titledesc2 finan">
                        <p className="card-text">Security Awareness</p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-12 crs-card">
                  <Link to="/health-and-safety-courses/">
                    <div className="card" style={{ width: "18rem" }}>
                      <img
                        className="card-img-top"
                        src="https://res.cloudinary.com/dhrgc7mqu/image/upload/v1606990669/succeedlearn/qdrqit2blcwwvnv6p2qp.png"
                        alt="infosec"
                        style={{ margin: 0 }}
                      />
                      <div className="card-body card-titledesc3 health">
                        <p className="card-text">Health & Safety</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }
}

financialcompliance.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default financialcompliance

export const financialhero = graphql`
  {
    allWordpressAcfSucceedlearnLogo {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressAcfSucceedlearnApple {
      edges {
        node {
          acf {
            logo_image_ {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }

    wordpressPage(title: { eq: "Financial compliance" }) {
      title
      acf {
        title
        clients_title
        description
        subtitle
        course_portfolio_title
        image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }
        adv_title
        image1desc
        imageone {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }
        image2
        imagesecond {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        image3
        imagethree {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          alt_text
        }

        crseTitle
        title_subtitle
        wordpress_1st_description
        crseImage1 {
          localFile {
            childImageSharp {
              fixed {
                src
                height
                width
              }
            }
          }
        }

        title2
        title_subtitle2
        wordpress_2nd_description
        crseImage2 {
          localFile {
            childImageSharp {
              fixed {
                src
                height
                width
              }
            }
          }
        }

        title3
        title_subtitle3
        wordpress_3rd_description
        crseImage3 {
          localFile {
            childImageSharp {
              fixed {
                src
                height
                width
              }
            }
          }
        }

        title4
        title_subtitle4
        wordpress_4th_description
        crseImage4 {
          localFile {
            childImageSharp {
              fixed {
                src
                height
                width
              }
            }
          }
        }

        human_image {
          localFile {
            childImageSharp {
              fixed {
                src
                height
                width
              }
            }
          }
        }
        demo_title_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        demo_subtitle_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights1_title_and_description
        crse_highlights1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights2_title_and_description
        crse_highlights2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights3_title_and_description
        crse_highlights3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights4_title_and_description
        crse_highlights4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights5_title_and_description
        crse_highlights5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights6_title_and_description
        crse_highlights6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights7_title_and_description
        crse_highlights7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights8_title_and_description
        crse_highlights8 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        crse_highlights9_title_and_description
        crse_highlights9 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        bottom {
          localFile {
            childImageSharp {
              fixed {
                src
                width
                height
              }
            }
          }
        }
      }
      yoast_head
    }

    allWordpressAcfClientsLogos {
      edges {
        node {
          acf {
            client_logo_images {
              localFile {
                childImageSharp {
                  fluid {
                    originalImg
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
