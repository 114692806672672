import React from "react"

import { Link } from "gatsby"
import Img from "gatsby-image"

import { FaAngleDoubleRight } from "react-icons/fa"

// financialcompliance page
const Coursedetails = props => {
  return (
    <>
      <section className="crse-portfolio financial" id="crse-portfolio">
        <div className="container">
          <div className="row info">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-12 mob-geo ">
              <div className="title-geo">
                <h3>{props.title}</h3>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>

          <div className="row">
            <div className="col-lg-8 col-md-12  geo-4 order-2 order-lg-1 order-md-2 ">
              <div className="card">
                <div className="card-header">
                  {props.crs4title}
                  <span className="subtitle"> {props.crse4subtitle}</span>
                </div>

                <div className="description">
                  <p>{props.crse4desc}</p>
                </div>
              </div>

              <div className="btn-geo-4">
                <Link to="/contact#schedule" c className="btn btn-geo-btn">
                  Get a Demo &nbsp; <FaAngleDoubleRight />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 geo-1-img order-1 order-lg-2 order-md-1 ">
              <Img fixed={props.img4} alt="SHPT" style={{ width: "350px" }} />
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-lg-4 col-md-12 geo-3-img">
              <Img fixed={props.img3} alt="SHPT" style={{ width: "350px" }} />
            </div>
            <div className="col-lg-8 col-md-12  geo-3">
              <div className="card">
                <div className="card-header">
                  {props.crs3title}
                  <span className="subtitle"> {props.crse3subtitle}</span>
                </div>

                <div className="description">
                  <p>{props.crse3desc}</p>
                </div>
              </div>

              <div className="btn-geo-3">
                <Link to="/contact#schedule" c className="btn btn-geo-btn">
                  Get a Demo &nbsp; <FaAngleDoubleRight />
                </Link>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <div className="col-lg-8 col-md-12  order-2  geo-2 order-2 order-lg-1 order-md-2 ">
              <div className="card">
                <div className="card-header">
                  {props.crs2title}
                  <span className="subtitle"> {props.crse2subtitle}</span>
                </div>

                <div className="description">
                  <p>{props.crse2desc}</p>
                </div>
              </div>

              <div className="btn-geo-2">
                <Link to="/contact#schedule" className="btn btn-geo-btn">
                  Get a Demo &nbsp; <FaAngleDoubleRight />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 geo-1-img order-1 order-lg-2 order-md-1">
              <Img fixed={props.img2} alt="SHPT" style={{ width: "350px" }} />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-12 geo-1-img">
              <Img fixed={props.img1} alt="SHPT" style={{ width: "350px" }} />
            </div>
            <div className="col-lg-8 col-md-12  geo-1">
              <div className="card">
                <div className="card-header">
                  {props.crs1title}
                  <span className="subtitle"> {props.crse1subtitle}</span>
                </div>

                <div className="description">
                  <p>{props.crse1desc}</p>
                </div>
              </div>
              <div className="btn-geo-1">
                <Link to="/contact#schedule" className="btn btn-geo-btn">
                  Get a Demo <FaAngleDoubleRight />
                </Link>
              </div>
            </div>
          </div>

          <hr />
        </div>
      </section>
    </>
  )
}

export default Coursedetails
